export const DATA_LAYER_EVENT = {
  LOCATION_ADD: 'location_add',
  LOCATION_REMOVED: 'location_removed',
  EQUIPMENT_ADD: 'equipment_add',
  EQUIPMENT_REMOVED: 'equipment_removed',
  PRICE_LIST_ADD: 'pricelist_add',
  PRICE_LIST_REMOVED: 'pricelist_removed',
  CAR_ADD: 'car_add',
  CAR_REMOVED: 'car_removed',
  PAGE_VIEW: 'page_view',
  GENERATE_LEAD: 'generate_lead',
  LOGIN: 'login',
}
