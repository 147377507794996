import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { SignInPage, SignUpPage, ResetPasswordPage } from 'pages/auth'
import { Profile } from 'modules/Profile'
import { Locations } from 'modules/Locations'
import { DropoffFee } from 'modules/DropoffFee'
import { Fleet } from 'modules/Fleet'
import { MainLayout } from 'layouts/MainLayout'
import { URLS } from 'constants/urls'
import { Applications } from 'modules/Applications'
import { ActivityHistory } from 'modules/ActivityHistory'
import { Dashboard } from 'modules/Dashboard'
import { NotFound } from 'ui/molecules/NotFound'
import { TermsAndConditions } from 'modules/TermsAndConditions'
import { useEffect } from 'react'
import { fetchUserData } from 'redux/login/thunks'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { UnauthorizedRoute } from 'routes/UnauthorizedRoute'
import { isDev } from 'utils/env'
import { GTM } from 'utils/gtm'
import { DATA_LAYER_EVENT } from 'constants/dataLayerEvents'
import { supplierIdSelector } from 'redux/common/selectors'
import { authIsLoadedSelector, isSupplierSelector } from 'redux/login/selectors'
import { BookingHistory } from 'modules/BookingHistory'
import { useDisableMenuItems } from 'hooks/useDisableMenuItems'
import { MENU_NAMES } from 'texts/menuNames'
import { MENU_PATHS } from 'layouts/MainLayout/Menu/constants'
import { cookiesService, SESSION_COOKIE } from 'utils/cookiesService'
import './styles.module.scss'

export const App = () => {
  const dispatch = useAppDispatch()
  const supplierCompanyId = useAppSelector(supplierIdSelector)
  const isLoaded = useAppSelector(authIsLoadedSelector)
  const { pathname } = useLocation()
  const isSupplier = useAppSelector(isSupplierSelector)
  const { disableMenuItem } = useDisableMenuItems()

  useEffect(() => {
    dispatch(fetchUserData())
  }, [])

  useEffect(() => {
    if (!isDev) {
      GTM.initialize()
    }
    if (isLoaded) {
      if (supplierCompanyId) {
        const currentMenuItem = Object.keys(MENU_PATHS).find((key) =>
          pathname.includes(MENU_PATHS[key])
        )
        const dataLayerData: { [key: string]: string } = {
          event: DATA_LAYER_EVENT.PAGE_VIEW,
          user_id: String(supplierCompanyId),
          page_location: window.location.href,
        }
        if (cookiesService.get('page_referrer')) {
          dataLayerData.page_referrer = cookiesService.get('page_referrer')
        }
        if (currentMenuItem) {
          dataLayerData.pageTitle = currentMenuItem
        }
        cookiesService.set(
          'page_referrer',
          window.location.href,
          SESSION_COOKIE
        )
        GTM.dataLayer(dataLayerData)
      }
    }
  }, [supplierCompanyId, isLoaded, pathname])

  return (
    <Routes>
      <Route index element={<Navigate replace to={URLS.SIGNIN} />} />
      <Route
        path={URLS.REGISTER}
        element={
          <UnauthorizedRoute>
            <SignUpPage />
          </UnauthorizedRoute>
        }
      />
      <Route
        path={URLS.SIGNIN}
        element={
          <UnauthorizedRoute>
            <SignInPage />
          </UnauthorizedRoute>
        }
      />
      <Route
        path={URLS.CONFIRM_REGISTRATION}
        element={
          <UnauthorizedRoute>
            <SignInPage />
          </UnauthorizedRoute>
        }
      />
      <Route
        path={URLS.FORGOT_PASSWORD}
        element={
          <UnauthorizedRoute>
            <ResetPasswordPage />
          </UnauthorizedRoute>
        }
      />
      <Route
        path={`${URLS.PROFILE}/*`}
        element={<MainLayout Content={<Profile />} />}
      />
      <Route
        path={`${URLS.LOCATIONS}/*`}
        element={<MainLayout Content={<Locations />} />}
      />
      <Route
        path={`${URLS.DROP_OFF_FEE}/*`}
        element={
          <MainLayout
            Content={<DropoffFee />}
            isUnavailable={disableMenuItem(MENU_NAMES.DROP_OFF_FEE)}
          />
        }
      />
      <Route
        path={`${URLS.FLEET}/*`}
        element={<MainLayout Content={<Fleet />} />}
      />
      <Route
        path={`${URLS.TERMS_AND_CONDITIONS}/*`}
        element={
          <MainLayout
            Content={<TermsAndConditions />}
            isUnavailable={disableMenuItem(MENU_NAMES.TERMS_AND_CONDITIONS)}
          />
        }
      />
      <Route
        path={`${URLS.ACTIVITY_HISTORY}/*`}
        element={
          <MainLayout
            Content={<ActivityHistory />}
            isUnavailable={isSupplier}
          />
        }
      />
      <Route
        path={`${URLS.APPLICATIONS}/*`}
        element={
          <MainLayout Content={<Applications />} isUnavailable={isSupplier} />
        }
      />
      <Route
        path={`${URLS.DASHBOARD}/*`}
        element={
          <MainLayout
            Content={<Dashboard />}
            isUnavailable={disableMenuItem(MENU_NAMES.DASHBOARD)}
          />
        }
      />
      <Route
        path={`${URLS.BOOKING_HISTORY}/*`}
        element={
          <MainLayout
            Content={<BookingHistory />}
            isUnavailable={disableMenuItem(MENU_NAMES.BOOKING_HISTORY)}
          />
        }
      />
      <Route path="*" element={<MainLayout Content={<NotFound />} />} />
    </Routes>
  )
}
