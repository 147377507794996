import Cookies, { Cookie } from 'universal-cookie'

export const SESSION_COOKIE = 'session'
const DEFAULT_DAYS_COUNT = 30
const DAY_IN_SECONDS = 86400
const PATH = '/'

class CookiesService {
  cookies = new Cookies()

  set = async (
    key: string,
    value: Cookie,
    days: string | number = DEFAULT_DAYS_COUNT
  ) => {
    this.cookies.set(key, value, {
      maxAge:
        days === SESSION_COOKIE ? undefined : (days as number) * DAY_IN_SECONDS,
      path: PATH,
    })
  }

  remove = (key: string) => {
    this.cookies.remove(key, { path: PATH })
  }

  get = (key: string) => this.cookies.get(key)
}

export const cookiesService = new CookiesService()
