import { addQueryString } from 'utils/addQueryString'
import { EmptyResponse, ErrorResponse } from '../types'
import { request } from '../utils'
import { PATH } from './constants'
import {
  CheckRegistrationCodeResponse,
  ConfirmVerificationCodeResponse,
  LoginResponse,
  SendVerificationCodeResponse,
  UserInfoResponse,
  VerifyEmailResponse,
} from './types'
import { EnvironmentVariablesService } from 'utils/env'

class Api {
  public baseUrl = EnvironmentVariablesService.getEnv('REACT_APP_API_URL') || ''

  async login(username?: string, password?: string) {
    return request<LoginResponse, ErrorResponse>(
      this.baseUrl,
      PATH.LOGIN,
      {
        method: 'POST',
      },
      { username, password }
    )
  }

  async logout() {
    await request<EmptyResponse, ErrorResponse>(this.baseUrl, PATH.LOGOUT, {
      method: 'POST',
    })
  }

  async register(registrationCode?: string, password?: string) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      PATH.REGISTRATION,
      {
        method: 'POST',
      },
      { registrationCode, password }
    )
  }

  async checkRegistrationCode(registrationCode: string) {
    return request<CheckRegistrationCodeResponse, ErrorResponse>(
      this.baseUrl,
      PATH.CHECK_REGISTRATION_CODE,
      { method: 'POST' },
      { registrationCode }
    )
  }

  async getUserInfo() {
    return request<UserInfoResponse, ErrorResponse>(
      this.baseUrl,
      PATH.USER_INFO,
      { method: 'GET' }
    )
  }

  async sendVerificationCodeRequest(email: string) {
    return request<SendVerificationCodeResponse, ErrorResponse>(
      this.baseUrl,
      PATH.SEND_VERIFICATION_CODE_REQUEST,
      { method: 'POST' },
      { email }
    )
  }

  async confirmVerificationCode(email: string, verificationCode: string) {
    return request<ConfirmVerificationCodeResponse, ErrorResponse>(
      this.baseUrl,
      PATH.CONFIRM_VERIFICATION_CODE,
      { method: 'POST' },
      { email, verificationCode }
    )
  }

  async resetPassword(passwordResetToken: string, newPassword: string) {
    return request<ConfirmVerificationCodeResponse, ErrorResponse>(
      this.baseUrl,
      PATH.RESET_PASSWORD,
      { method: 'POST' },
      { passwordResetToken, newPassword }
    )
  }

  async sendRegistrationCode(companyId: number) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      PATH.SEND_REGISTRATION_CODE_REQUEST,
      { method: 'POST' },
      { companyId }
    )
  }

  async verifyUserEmail(email: string) {
    return request<VerifyEmailResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.VERIFY_EMAIL, { email }),
      { method: 'GET' }
    )
  }

  async confirmRegistration(registrationCode: string) {
    return request<{ companyId: number }, ErrorResponse>(
      this.baseUrl,
      PATH.CONFIRM_REGISTRATION,
      { method: 'POST' },
      { registrationCode }
    )
  }
}

export const authApi = new Api()
